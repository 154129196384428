<template>
  <div id="contestDetail">
    <img class="theme" src="@/assets/img/detailPic/theme.png" alt="" />
    <div id="theme-video" class="theme-video"></div>
    <img class="progress" src="@/assets/img/detailPic/progress.png" alt="" />
    <div class="invited">
      <img class="part3" src="@/assets/img/part3.png" alt="">
      <img class="invited-title" src="@/assets/img/detailPic/invited-title.png" alt="">
      <div class="first-invited">
        <el-carousel indicator-position="inside" height="500px">
          <el-carousel-item v-for="(item, index) in invited.invitedList1" :key="item">
            <el-image :src="item" style="width: 940px; height: 500px" fit="contain" @click="onPreview(index, 1)" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <p>一个完颜《字形城市》</p>
      <el-image class="second-invited"
        src="http://benqgroup.oss-cn-hangzhou.aliyuncs.com/design-competition/invited/2-1.jpg"
        style="width: 940px; height: 435px" fit="contain" @click="onPreview(index, 2)" />
      <p>林钿CG《显示 "真" 未来》</p>
    </div>
    <div class="prize-wrap">
      <a class="prize-btn"
        href="https://mp.weixin.qq.com/s?__biz=MzU0NDU0NTg1Mw==&mid=2247497686&idx=1&sn=198f5e109f1e1299391fa48c6515effb&chksm=fb783a84cc0fb3921aaba667b02b007a17ebaf12045e080461203d413902a1bfa8d9b95561f2#rd">点击查看奖品介绍</a>
      <img class="prize" src="@/assets/img/detailPic/prize.png" alt="" />
    </div>
    <div class="judge-wrap">
      <div class="link-wrap">
        <a class="link" v-for="item in  judgeList.list" :key="item" :href="item"></a>
      </div>
      <img class="judge" src="@/assets/img/detailPic/judge.png" alt="" />
    </div>
    <div class="weibo-wrap">
      <a class="weibo-btn" href="https://weibo.com/6242868635/MDCb7FFBY"></a>
      <img class="weibo" src="@/assets/img/detailPic/weibo.png" alt="" />
    </div>
    <img class="about" @click="goLink('https://3.cn/-109USWmJ')" src="@/assets/img/detailPic/about.png" alt="" />
    <elImageViewer v-if="showViewer" @close="closeViewer" :url-list="invited.viewerImgList" :z-index="3000">
    </elImageViewer>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";
import Player from 'xgplayer';
export default {
  name: "contestDetail",
  setup() {
    const showViewer = ref(false)
    const router = useRouter();
    const judgeList = reactive(
      {
        list: [
          "https://www.xiaohongshu.com/user/profile/60c8de3b0000000001006cb4?xhsshare=CopyLink&appuid=5af923364eacab193d0281fe&apptime=1681118341",
          "https://weibo.com/u/3256013033",
          "https://vip.jsboom.com/",
          "https://zitisheji.com",
          "https://oyart.zcool.com.cn/",
          "https://weibo.com/dzmk",
          "https://weibo.com/u/2191289095",
          "https://weibo.com/u/1861754893",
          "https://weibo.com/euginnx",
          "https://weibo.com/u/2802874002",
          "https://weibo.com/raystory",
          "https://www.xiaohongshu.com/user/profile/5ed6485a000000000101ebbe?xhsshare=CopyLink&appuid=5ed6485a000000000101ebbe&apptime=1681098898",
          "",
          "",
          ""
        ]
      }
    )
    const invited = reactive({
      invitedList1: [
        "http://benqgroup.oss-cn-hangzhou.aliyuncs.com/design-competition/invited/1-1.jpg",
        "http://benqgroup.oss-cn-hangzhou.aliyuncs.com/design-competition/invited/1-2.jpg",
        "http://benqgroup.oss-cn-hangzhou.aliyuncs.com/design-competition/invited/1-3.jpg",
        "http://benqgroup.oss-cn-hangzhou.aliyuncs.com/design-competition/invited/1-4.jpg",
        "http://benqgroup.oss-cn-hangzhou.aliyuncs.com/design-competition/invited/1-5.jpg",
        "http://benqgroup.oss-cn-hangzhou.aliyuncs.com/design-competition/invited/1-6.jpg",
        "http://benqgroup.oss-cn-hangzhou.aliyuncs.com/design-competition/invited/1-7.jpg",
        "http://benqgroup.oss-cn-hangzhou.aliyuncs.com/design-competition/invited/1-8.jpg",
        "http://benqgroup.oss-cn-hangzhou.aliyuncs.com/design-competition/invited/1-9.jpg",
      ],
      invitedList2: [
        "http://benqgroup.oss-cn-hangzhou.aliyuncs.com/design-competition/invited/2-1.jpg"
      ],
      viewerImgList: []
    })
    function uploadFile() {
      router.push({ path: "/upLoadFile" });
      document.documentElement.scrollTop = 820;
    }
    function goLink(item) {
      window.location.href = item;
    }
    function onPreview(index, type) {
      showViewer.value = true
      let tempImgList
      if (type === 1) {
        tempImgList = [...invited.invitedList1];
      } else {
        tempImgList = [...invited.invitedList2];
      }
      let temp = [];
      for (let i = 0; i < index; i++) {
        temp.push(tempImgList.shift());
      }
      invited.viewerImgList = tempImgList.concat(temp);
    }
    // 关闭查看器
    function closeViewer() {
      showViewer.value = false
    }

    return {
      uploadFile,
      judgeList,
      goLink,
      invited,
      showViewer,
      onPreview,
      closeViewer
    };
  },
  methods: {
    initVideo(id, url, poster) {
      let player = new Player({
        id,
        url,
        poster,
        definitionActive: 'hover',
        width: '325',
        height: '188.5',
      });
      player.emit('resourceReady');
    }
  },
  mounted() {
    this.initVideo('theme-video',
      'https://benqgroup.oss-cn-hangzhou.aliyuncs.com/design-competition/design-competition-resources/0512_PD%E5%8D%83%E5%9F%8E%E4%B8%87%E8%B1%A1_Final_%E5%B0%8F%E6%AA%94.mp4',
      'http://benqgroup.oss-cn-hangzhou.aliyuncs.com/design-competition/design-competition-resources/banner1.jpg'
    )
  }
};
</script>

<style lang="less" scoped>
@font-face {
  font-family: "FZLTHJW";
  src: url("../assets/fonts/FZLTHJW.TTF") format("truetype");
}

#contestDetail {
  padding-top: 177px;
  box-sizing: border-box;
  height: 8498px;
  background-image: url("../assets/img/detailPic/bg.png");
  background-position: center;
  position: relative;

  &>img {
    display: block;
    margin: 0 auto;
  }

  .theme {
    margin-bottom: 60px;
  }

  .theme-video {
    display: block;
    width: 925px;
    height: 520px;
    // background-image: url('../assets/img/detailPic/video.png');
    // background-size: cover;
    // background-position: center;
    margin: 0 auto;
    margin-bottom: 110px;
  }

  .progress {
    margin-bottom: 106px;
  }

  .invited {
    margin-bottom: 121px;

    &>img {
      display: block;
      margin: 0 auto;
    }

    .part3 {
      width: 105px;
      height: 16px;
      margin-bottom: 23px;
    }

    .invited-title {
      width: 222px;
      height: 72px;
      margin-bottom: 56px;
    }

    .first-invited,
    .second-invited {

      display: block;
      margin: 0 auto;
      width: 940px;
      border-radius: 16px;
      margin-bottom: 19px;
    }

    &>p {
      width: 940px;
      margin: 0 auto;
      margin-bottom: 59px;
      color: #fff;

    }
  }

  .prize-wrap {
    width: 1030px;
    margin: 0 auto;
    position: relative;

    .prize-btn {
      display: block;
      text-decoration: none;
      /* 去除默认的下划线 */
      color: #000;
      /* 去除默认的颜色和点击后变化的颜色 */
      width: 240px;
      height: 50px;
      text-align: center;
      line-height: 50px;
      color: #355381;
      font-size: 24px;
      background: linear-gradient(0deg, #9bc4fc 0%, #bee9fa 100%);
      border-radius: 8px;
      position: absolute;
      bottom: 258px;
      left: 50%;
      transform: translateX(-50%);
    }

    .prize {
      display: block;
      width: 1030px;
      margin: 0 auto;
      margin-bottom: 118px;
    }
  }

  .judge-wrap {
    width: 1030px;
    margin: 0 auto;
    margin-bottom: 125px;
    position: relative;

    .judge {
      display: block;
      width: 1030px;
      margin: 0 auto;
      margin-bottom: 59px;
    }

    .link-wrap {
      position: absolute;
      top: 230px;
      left: 0;
      right: 0;
      width: 1030px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: left;

      .link {
        display: block;
        width: 170px;
        height: 220px;
        margin-bottom: 223px;
        margin-right: 3px;
        // background-color: #fff;
      }
    }

  }

  .weibo-wrap {
    width: 1030px;
    margin: 0 auto;
    position: relative;

    .weibo-btn {
      position: absolute;
      left: 204px;
      bottom: 58px;
      width: 233px;
      height: 26px;
    }

    .weibo {
      margin-bottom: 183px;
      display: block;
      width: 1030px;
      margin: 0 auto;
      margin-bottom: 230px;
    }
  }
  ::v-deep .xgplayer-skin-default .xgplayer-poster {
    background-size: contain;
    background-repeat: no-repeat;
  }
}
</style>
